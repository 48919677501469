.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.mt-20px {
	margin-top: 20px;
}

.mt-24px {
	margin-top: 24px;
}

.mt-16px {
	margin-top: 16px;
}

.mt-8px {
	margin-top: 8px;
}

.mt-48px {
	margin-top: 48px;
}

.mt-36px {
	margin-top: 36px;
}

.my-64px {
	margin-top: 64px;
	margin-bottom: 64px;
}

