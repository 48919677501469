.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.min-w-300px {
	min-width: 300px !important;
}

.max-w-900px {
	max-width: 900px !important;
}

.w-90 {
	width: 90% !important;
}

.min-w-0px {
	min-width: 0px !important;
}

.max-w-300px {
	max-width: 300px !important;
}

.w-196px {
	width: 196px !important;
}

.max-w-500px {
	max-width: 500px !important;
}

.max-w-800px {
	max-width: 800px !important;
}

.max-w-1200px {
	max-width: 1200px !important;
}

.w-128px {
	width: 128px !important;
}

